// HexCraft.jsx

import React, { useRef, useEffect } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import {
  CameraControls,
  PerspectiveCamera,
  KeyboardControls,
  OrbitControls,
} from "@react-three/drei";
import Lighting from "./Lighting";
import { useWindowResize } from "./utils";
import { Stats } from "@react-three/drei";
import useStore from "../../stores/store";
import UI from "./ui";
import HexWorldComponent from "./HexWorld";
import { Physics } from "@react-three/rapier";

const HexCraft = () => {
  const { theme } = useStore();
  const worldRef = useRef();

  return (
    <KeyboardControls
      map={[
        { name: "forward", keys: ["ArrowUp", "w", "W"] },
        { name: "backward", keys: ["ArrowDown", "s", "S"] },
        { name: "left", keys: ["ArrowLeft", "a", "A"] },
        { name: "right", keys: ["ArrowRight", "d", "D"] },
        { name: "up", keys: ["Space"] },
        { name: "down", keys: ["ShiftLeft", "ShiftRight"] },
        { name: "rollLeft", keys: ["q", "Q"] },
        { name: "rollRight", keys: ["e", "E"] },
      ]}
    >
      <Canvas shadows className="canvas">
        <color attach="background" args={[theme.bodyBgPrimary]} />
        <Physics>
          <Scene worldRef={worldRef} />
        </Physics>
      </Canvas>
      <UI worldRef={worldRef} />
    </KeyboardControls>
  );
};

const Scene = ({ worldRef }) => {
  const controlsRef = useRef();
  const { camera } = useThree();

  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.setLookAt(30, 20, 30, 0, 0, 0, true);
    }
  }, []);

  useEffect(() => {
    console.log("Camera position:", camera.position);
    console.log("Camera rotation:", camera.rotation);
  }, [camera]);

  useWindowResize();

  return (
    <>
      <Stats showPanel={0} className="stats" />
      <PerspectiveCamera
        makeDefault
        position={[-32, 16, -32]}
        fov={70}
        near={0.1}
        far={1000}
      />
      <Lighting />
      <HexWorldComponent
        ref={worldRef}
        size={{ radius: 32, height: 32 }}
        params={{
          seed: 0,
          terrain: {
            scale: 30,
            magnitude: 0.5,
            offset: 0.2,
          },
          resources: {
            // Add resource parameters here if needed
          },
        }}
      />
      <OrbitControls />
    </>
  );
};

export default HexCraft;
