import React, { useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import useStore from "../../../stores/store";
import useConfiguratorStore from "../configuratorStore";

const S3_BUCKET_URL = "https://site-davidbickley.s3.us-east-2.amazonaws.com";

export function Chair(props) {
  const { theme } = useStore();
  const { nodes } = useGLTF(`${S3_BUCKET_URL}/models/chair.glb`);
  const { materials, initMaterials } = useConfiguratorStore();

  useEffect(() => {
    initMaterials(theme);
  }, [theme, initMaterials]);

  if (!materials.legs) {
    return null;
  }

  return (
    <group {...props} dispose={null}>
      <group scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Chair_1.geometry}
          material={materials.outerShell}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Chair_2.geometry}
          material={materials.innerShell}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Chair_3.geometry}
          material={materials.cushion}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Chair_4.geometry}
          material={materials.bottomShell}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Chair_5.geometry}
          material={materials.legs}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Chair_6.geometry}
          material={materials.feet}
        />
      </group>
    </group>
  );
}

useGLTF.preload(`${S3_BUCKET_URL}/models/chair.glb`);
