// HexWorld.js
import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import * as THREE from "three";
import { SimplexNoise } from "three/examples/jsm/math/SimplexNoise.js";
import { RNG } from "./rng";
import { blocks, resources } from "./Blocks";

class HexWorld extends THREE.Group {
  constructor(size = { radius: 16, height: 16 }, params = {}) {
    super();
    this.size = size;
    this.params = {
      seed: 0,
      terrain: {
        scale: 30,
        magnitude: 0.5,
        offset: 0.2,
        ...(params.terrain || {}),
      },
      resources: params.resources || {},
    };
    this.meshes = new Map();
    this.generate();
  }

  generate() {
    this.clear();
    const rng = new RNG(this.params.seed);
    this.initializeTerrain();
    this.generateTerrain(rng);
    this.generateResources(rng);
    this.generateMeshes();
  }

  updateParams(newSize, newParams = {}) {
    this.size = newSize || this.size;
    this.params = {
      ...this.params,
      seed: newParams.seed !== undefined ? newParams.seed : this.params.seed,
      terrain: { ...this.params.terrain, ...(newParams.terrain || {}) },
      resources: newParams.resources
        ? { ...this.params.resources, ...newParams.resources }
        : this.params.resources,
    };
    this.generate();
  }

  initializeTerrain() {
    this.data = new Map();
  }

  createHexagonGeometry() {
    return new THREE.CylinderGeometry(1, 1, 1, 6).rotateX(Math.PI / 0.5);
  }

  generateMeshes() {
    const geometry = this.createHexagonGeometry();
    const material = new THREE.MeshPhongMaterial({ flatShading: true });

    const blockTypes = new Set(
      Array.from(this.data.values()).map((block) => block.id)
    );

    blockTypes.forEach((blockTypeId) => {
      if (blockTypeId !== blocks.empty.id) {
        const blockTypeInstances = Array.from(this.data.entries()).filter(
          ([, block]) =>
            block.id === blockTypeId && !this.isBlockObscured(...block.position)
        );

        if (blockTypeInstances.length > 0) {
          const mesh = new THREE.InstancedMesh(
            geometry,
            material,
            blockTypeInstances.length
          );
          mesh.count = blockTypeInstances.length;
          mesh.castShadow = true;
          mesh.receiveShadow = true;

          const matrix = new THREE.Matrix4();
          const color = new THREE.Color();

          blockTypeInstances.forEach(([, block], index) => {
            const [x, z] = this.hexToPixel(
              block.position[0],
              block.position[1]
            );
            matrix.makeTranslation(x, block.position[2], z);
            mesh.setMatrixAt(index, matrix);

            const blockType = Object.values(blocks).find(
              (b) => b.id === blockTypeId
            );
            color.setHex(blockType?.color || 0xcccccc);
            mesh.setColorAt(index, color);
          });

          mesh.instanceMatrix.needsUpdate = true;
          if (mesh.instanceColor) mesh.instanceColor.needsUpdate = true;
          this.add(mesh);
          this.meshes.set(blockTypeId, mesh);
        }
      }
    });
  }

  generateTerrain(rng) {
    const simplex = new SimplexNoise(rng);
    const minHeight = 1; // Minimum height to ensure no holes in the floor

    for (let q = -this.size.radius; q <= this.size.radius; q++) {
      for (
        let r = Math.max(-this.size.radius, -q - this.size.radius);
        r <= Math.min(this.size.radius, -q + this.size.radius);
        r++
      ) {
        const [x, z] = this.hexToPixel(q, r);
        const value = simplex.noise(
          x / this.params.terrain.scale,
          z / this.params.terrain.scale
        );
        const scaledNoise =
          this.params.terrain.offset + this.params.terrain.magnitude * value;
        const height = Math.max(
          minHeight,
          Math.floor(this.size.height * scaledNoise)
        );

        for (let y = 0; y < height; y++) {
          this.setBlock(q, r, y, blocks.dirt.id);
        }
        // Always set the top block to grass
        this.setBlock(q, r, height - 1, blocks.grass.id);
      }
    }
  }

  generateResources(rng) {
    const simplex = new SimplexNoise(rng);

    this.data.forEach((block, key) => {
      const [q, r, y] = key.split(",").map(Number);
      const [x, z] = this.hexToPixel(q, r);

      for (const resource of resources) {
        const resourceParams = this.params.resources[resource.name] || {};
        const scale = resourceParams.scale ||
          resource.scale || { x: 30, y: 30, z: 30 };
        const scarcity =
          resourceParams.scarcity !== undefined
            ? resourceParams.scarcity
            : resource.scarcity;

        const value = simplex.noise3d(x / scale.x, y / scale.y, z / scale.z);

        if (value > scarcity) {
          this.setBlock(q, r, y, resource.id);
          break;
        }
      }
    });
  }

  hexToPixel(q, r) {
    return [1.732 * q + 0.866 * r, 1.5 * r];
  }

  setBlock(q, r, y, id) {
    const blockType = Object.values(blocks).find((b) => b.id === id);
    if (!blockType) {
      console.warn(`Attempting to set unknown block type: ${id}`);
      id = blocks.empty.id; // Default to empty block if type is unknown
    }
    this.data.set(`${q},${r},${y}`, { id, position: [q, r, y] });
  }

  isBlockObscured(q, r, y) {
    const neighbors = [
      [1, 0, 0],
      [-1, 0, 0],
      [0, 1, 0],
      [0, -1, 0],
      [1, -1, 0],
      [-1, 1, 0],
      [0, 0, 1],
      [0, 0, -1],
    ];

    return neighbors.every(([dq, dr, dy]) => {
      const neighborBlock = this.data.get(`${q + dq},${r + dr},${y + dy}`);
      return neighborBlock && neighborBlock.id !== blocks.empty.id;
    });
  }
}

const HexWorldComponent = forwardRef(({ size, params }, ref) => {
  const worldRef = useRef();

  useImperativeHandle(ref, () => ({
    generateMeshes: (newParams) => {
      if (worldRef.current && newParams && newParams.size && newParams.params) {
        worldRef.current.updateParams(newParams.size, newParams.params);
      }
    },
  }));

  useEffect(() => {
    if (!worldRef.current) {
      worldRef.current = new HexWorld(size, params);
    } else {
      worldRef.current.updateParams(size, params);
    }
  }, [size, params]);

  return worldRef.current ? <primitive object={worldRef.current} /> : null;
});

export default HexWorldComponent;
