import React from "react";

const Lighting = () => {
  return (
    <>
      <ambientLight intensity={0.5} />
      <directionalLight
        position={[3, 3, 3]}
        intensity={1}
        castShadow
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
      <pointLight position={[0, 50, 0]} intensity={0.5} />
    </>
  );
};

export default Lighting;
