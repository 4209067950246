// UI.js
import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  useMemo,
} from "react";
import { useControls, button, folder } from "leva";
import { resources } from "./Blocks";

function UI({ worldRef }) {
  const prevParamsRef = useRef({});
  const [debugInfo, setDebugInfo] = useState("");

  const addDebugInfo = useCallback((info) => {
    setDebugInfo((prev) => `${info}\n${prev}`);
  }, []);

  const createResourceControls = useMemo(() => {
    let resourceControls = {};
    resources.forEach((resource) => {
      resourceControls[resource.name] = folder({
        [`${resource.name}ScaleX`]: {
          value: resource.scale?.x || 30,
          min: 1,
          max: 100,
          step: 1,
        },
        [`${resource.name}ScaleY`]: {
          value: resource.scale?.y || 30,
          min: 1,
          max: 100,
          step: 1,
        },
        [`${resource.name}ScaleZ`]: {
          value: resource.scale?.z || 30,
          min: 1,
          max: 100,
          step: 1,
        },
        [`${resource.name}Scarcity`]: {
          value: resource.scarcity || 0.5,
          min: 0,
          max: 1,
          step: 0.01,
        },
      });
    });
    return resourceControls;
  }, []);

  const [params, setParams] = useControls(() => ({
    radius: { value: 32, min: 1, max: 100, step: 1 },
    height: { value: 32, min: 1, max: 200, step: 1 },
    seed: { value: 0, min: 0, max: 99999, step: 1 },
    scale: { value: 30, min: 1, max: 100, step: 1 },
    magnitude: { value: 0.5, min: 0, max: 1, step: 0.01 },
    offset: { value: 0.2, min: 0, max: 1, step: 0.01 },
    ...createResourceControls,
    regenerateTerrain: button(() => regenerateWorld(true)),
  }));

  const regenerateWorld = useCallback(
    (force = false) => {
      if (worldRef.current && worldRef.current.generateMeshes) {
        const resourceParams = {};
        resources.forEach((resource) => {
          resourceParams[resource.name] = {
            scale: {
              x: params[`${resource.name}ScaleX`],
              y: params[`${resource.name}ScaleY`],
              z: params[`${resource.name}ScaleZ`],
            },
            scarcity: params[`${resource.name}Scarcity`],
          };
        });

        const currentParams = {
          size: { radius: params.radius, height: params.height },
          params: {
            seed: params.seed,
            terrain: {
              scale: params.scale,
              magnitude: params.magnitude,
              offset: params.offset,
            },
            resources: resourceParams,
          },
        };

        if (
          force ||
          JSON.stringify(currentParams) !==
            JSON.stringify(prevParamsRef.current)
        ) {
          worldRef.current.generateMeshes(currentParams);
          prevParamsRef.current = currentParams;
          addDebugInfo("World regeneration complete");
        }
      }
    },
    [params, worldRef, addDebugInfo]
  );

  useEffect(() => {
    regenerateWorld();
  }, [regenerateWorld]);
}

export default React.memo(UI);
