import { Canvas, useThree } from "@react-three/fiber";
import React, { Suspense, useEffect } from "react";
import useStore from "../../stores/store";
import {
  Environment,
  OrbitControls,
  MeshReflectorMaterial,
} from "@react-three/drei";
import { RectAreaLightUniformsLib } from "three/examples/jsm/lights/RectAreaLightUniformsLib";
import { Chair } from "./Chair/Chair";
import * as THREE from "three";
import ConfiguratorUI from "./ConfiguratorUI";

RectAreaLightUniformsLib.init();

function FogControl() {
  const { scene } = useThree();
  const { theme } = useStore();

  useEffect(() => {
    scene.fog = new THREE.FogExp2(theme.bodyBgPrimary, 0.05);
  }, [scene, theme]);

  return null;
}

const Configurator = () => {
  const { theme } = useStore();

  return (
    <>
      <ConfiguratorUI />
      <Canvas
        className="canvas"
        shadows
        gl={{ antialias: true }}
        dpr={[1, 1.5]}
        camera={{ position: [2, 0.1, 4], fov: 20 }}
      >
        <color attach="background" args={[theme.bodyBgPrimary]} />
        <FogControl />
        <Suspense>
          <Chair position={[0, -0.5, 0]} />
        </Suspense>
        <mesh
          position={[0, -0.5, 0]}
          scale={[2, 2, 1]}
          rotation={[-Math.PI / 2, 0, Math.PI]}
          receiveShadow
        >
          <planeGeometry args={[70, 70]} />
          <MeshReflectorMaterial
            blur={[0, 0]} // Blur ground reflections (width, height), 0 skips blur
            mixBlur={1} // How much blur mixes with surface roughness (default = 1)
            mixStrength={0.5} // Strength of the reflections
            mixContrast={1} // Contrast of the reflections
            resolution={1024} // Off-buffer resolution, lower=faster, higher=better quality, slower
            mirror={0} // Mirror environment, 0 = texture colors, 1 = pick up env colors
            depthScale={0} // Scale the depth factor (0 = no depth, default = 0)
            minDepthThreshold={0.9} // Lower edge for the depthTexture interpolation (default = 0)
            maxDepthThreshold={1} // Upper edge for the depthTexture interpolation (default = 0)
            depthToBlurRatioBias={0.25}
            color={theme.bodyBgPrimary}
            metalness={0.75}
          />
        </mesh>
        <Environment preset="studio" environmentIntensity={0.5} />
        <directionalLight intensity={2.5} castShadow position={[50, 25, 35]} />
        <OrbitControls
          minPolarAngle={0}
          maxPolarAngle={Math.PI / 1.9}
          makeDefault
        />
      </Canvas>
    </>
  );
};

export default Configurator;
